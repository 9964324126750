import i18n from "../locales/index"
import { env } from "@/utils"

const envName = env()
export default {
  gLoading: false,
  secondaryScreenData: {
    cartlist: [],
    total: 0,
    change_amount: 0,
    collection_amount: 0,
    isDisplayChange: false,
    setting: {
      screenType: localStorage.getItem("second_screen_type"), // "1" 轮播图 "0" 视频
      alwayShowMedia: localStorage.getItem("alwayShowMedia"), // "1" 显示轮播图 "0" 显示视频
      fixedOrder: localStorage.getItem("fixedOrder"), // 否固定左侧订单区 "0" 否 “1”是
      swicthTime: localStorage.getItem("swicthTime"), // 轮播图切换播放时间 time 单位是秒
    },
  },
  memberInfo: null,
  order: {
    orderNo: "",
    totalPrice: "",
    discountedTotal: "",
    discountValue: "100",
    realAmount: "",
    changeAmount: 0,
    smallDiscount: false,
    customerId: null,
    order_items: [],
  },
  fetchOrderData: {},
  successionDetailData: {
    id: null,
    user_id: null,
    start_at: "",
    end_at: null,
    created_at: "",
    updated_at: "",
    shop_id: null,
  },

  // 设置相关
  settings: {},
  baudList: ["9600", "115200", "2400"],
  comList:
    envName === "android"
      ? ["/dev/ttyS0", "/dev/ttyS1", "/dev/ttyS2", "/dev/ttyS3", "/dev/ttyS4"]
      : [
          "com1",
          "com2",
          "com3",
          "com4",
          "com5",
          "com6",
          "com7",
          "com8",
          "com9",
          "/dev/tty.usbserial",
          "/dev/cu.usbserial-14310",
          "/dev/cu.usbserial-14210",
          "/dev/cu.usbserial-14330",
          "/dev/cu.usbserial-14430",
        ],
  scaleTypeList:
    envName === "android"
      ? [
          { name: i18n.t("store_state_857"), type: "0" },
          { name: i18n.t("store_state_858"), type: "1" },
          { name: i18n.t("store_state_859"), type: "2", baud: "115200" },
          { name: i18n.t("store_state_860"), type: "3" },
          { name: i18n.t("store_state_861"), type: "4" },
          { name: i18n.t("store_state_862"), type: "5" },
          { name: i18n.t("store_state_863"), type: "6" },
          { name: i18n.t("store_state_858"), type: "7" },
          { name: i18n.t("store_state_864"), type: "8" },
        ]
      : [
          { name: i18n.t("store_state_857"), type: "0" },
          // { name: '顶尖电子秤(新)', type: '8' },
          { name: i18n.t("store_state_858"), type: "1" },
          { name: i18n.t("store_state_859"), type: "2" },
          { name: i18n.t("store_state_860"), type: "3" },
          { name: i18n.t("store_state_861"), type: "4" },
          { name: i18n.t("store_state_862"), type: "5" },
          { name: i18n.t("store_state_863"), type: "6" },
          { name: i18n.t("store_state_858"), type: "7" },
          { name: i18n.t("store_state_888"), type: "9" },         
        ],
  bluetoothScaleTypeList: [{ name: i18n.t("store_state_857"), type: "0" }],
  receiptWidths: ["58", "80"],
  small_change_discount_digits: [
    [i18n.t("store_state_865"), "shiyuan"],
    [i18n.t("store_state_866"), "yuan"],
    [i18n.t("store_state_867"), "jiao"],
    [i18n.t("store_state_868"), "fen"],
    [i18n.t("store_state_869"), "shiyuan-round"],
    [i18n.t("store_state_870"), "yuan-round"],
    [i18n.t("store_state_871"), "jiao-round"],
    [i18n.t("store_state_872"), "fen-round"],
  ],
  second_screen_type_list: [
    { title: i18n.t("pages_secondaryscreen_index_725"), value: "1" },
    { title: i18n.t("pages_secondaryscreen_index_726"), value: "0" },
  ],
  mergeSameGood: false, // 自动合并相同商品
  memberDiscount: false, // 会员价允许打折
  prepkgChangePrice: false, // 预包装是否允许改价
  isPettyCash: true,

  ticket_qr_show: false,
  pickup_code_show: false,
  pickup_code_alias_name: "",
  small_discount: false,
  small_change_discount_digit: "fen",
  receipt_width: "",
  user_says: i18n.t("store_state_873"),
  material_alias_name: i18n.t("store_state_874"),
  auto_close_estimation: localStorage.getItem("auto_close_estimation") === "1", // 交班后自动清空所有估清数量
  receiptsList: [
    { name: i18n.t("store_state_875"), value: 0 },
    { name: i18n.t("store_state_876"), value: 1 },
    { name: i18n.t("store_state_877"), value: 2 },
    { name: i18n.t("store_state_878"), value: 3 },
  ],
  exchange_receipts: 1,
  cashing_receipts: 1,
}
